import { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { t } from '../utils/i18n';
import { isProduction } from '../utils/env';
import { usePageByKey } from '../hooks/use-pages';
import { getItem, setItem } from '../utils/cookies';

import GoogleAnalytics from './google-analytics';
import * as styles from './cookie-consent.module.scss';

//
// CONSTANTS
//
const CC_KEY = '__ccs';
const CC_VALUE = 'yes';

//
// DNT FUNCTION
// https://developer.mozilla.org/de/docs/Web/HTTP/Headers/DNT
//
const hasDoNotTrackHeader = () => {
	if (typeof window === 'undefined') {
		return false;
	}

	const value = navigator.doNotTrack || window.doNotTrack;

	switch (value) {
		case '1':
		case 'yes':
			return true;
		default:
			return false;
	}
};

//
// COOKIE CONSENT
//
const CookieConsent = ({ isDebug }) => {
	const [isAccepted, setIsAccepted] = useState(getItem(CC_KEY) === CC_VALUE);
	const onAccept = () => {
		setItem(CC_KEY, CC_VALUE);
		setIsAccepted(true);
	};
	const doNotTrack = hasDoNotTrackHeader();
	const { slug } = usePageByKey('privacy');

	if (!isProduction && !isDebug) {
		return null;
	}

	if (isAccepted) {
		return !doNotTrack ? <GoogleAnalytics /> : null;
	}

	return (
		<div className={styles.wrapper}>
			<div className={styles.consent}>{t('COOKIES_CONSENT')}</div>
			<div className={styles.buttons}>
				<button onClick={onAccept} className={styles.accept}>
					{t('COOKIES_BUTTON')}
				</button>
				<Link to={slug} className={styles.privacy}>
					{t('COOKIES_PRIVACY')}
				</Link>
			</div>
		</div>
	);
};

CookieConsent.defaultProps = {
	isDebug: false,
};

CookieConsent.propTypes = {
	isDebug: PropTypes.bool.isRequired,
};

export default CookieConsent;
