import { Helmet } from 'react-helmet';

import { isProduction } from '../utils/env';
import useSiteMeta from '../hooks/use-site-meta';

//
// TRACKING FUNCTION
//
export const trackPageView = () => {
	// requires analytics to be loaded
	// @see: GoogleAnalytics component
	if (typeof gtrack === 'undefined') {
		return;
	}

	// eslint-disable-next-line
	gtrack();
};

//
// GOOGLE ANALYTICS
//
const GoogleAnalytics = () => {
	const { gaAnonymizeIp, gaTrackingId, hostName } = useSiteMeta();

	return (
		<Helmet>
			<script
				async
				key="ga-base-script"
				src={`https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`}></script>
			<script key="ga-setup-script">{`
				window.dataTrack = ${isProduction};
				window.dataLayer = window.dataLayer || [];
				function gtag(){dataLayer.push(arguments);}
				function gtrack() {
					var pagePath = location.pathname + location.search + location.hash;
					if (window.dataTrack && location.hostname.indexOf('${hostName}') >= 0) {
						gtag('config', '${gaTrackingId}', {
							anonymize_ip: ${gaAnonymizeIp},
							page_path: pagePath
						});
					}
				}

				gtag('js', new Date());
				gtrack();
			`}</script>
		</Helmet>
	);
};

export default GoogleAnalytics;
