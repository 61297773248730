import { useStaticQuery, graphql } from 'gatsby';

const invokeQueryAndMapData = () => {
  const {
    site: {
      siteMetadata: data
    }
  } = useStaticQuery(query);

  return data;
};

export default invokeQueryAndMapData;

const query = graphql`
  query useSiteMeta {
    site {
      siteMetadata {
        siteUrl
        gaAnonymizeIp
        gaTrackingId
        hostName
      }
    }
  }
`;